import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import { ComicList } from "../components/ComicList"
import NewsList from "../components/NewsList"

///

class HomePage extends React.Component {
  render() {
    const { data } = this.props
    let posts = data.allMarkdownRemark.edges
    const news = data.news

    // only return posts in root /comics/ folder
    const newPosts = posts.filter(post => {
      let splitSegments = post.node.fields.slug.split("/").length
      return splitSegments < 5
    })
    posts = newPosts

    ///

    return (
      <Layout location={this.props.location}>
        <SEO />
        <NewsList data={news.html} />
        <ComicList data={posts} variant="card" />
      </Layout>
    )
  }
}

export default HomePage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      filter: { fields: { slug: { regex: "/comics/" } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "YYYY/MM/DD")
            title
            description
            lang
            linkExternal {
              label
              url
            }
            thumbnail {
              childImageSharp {
                fluid(maxWidth: 512, maxHeight: 256, quality: 50) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
      }
    }
    news: markdownRemark(fileAbsolutePath: { regex: "/home-news/" }) {
      frontmatter {
        title
      }
      html
    }
  }
`
