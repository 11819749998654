import React, { Component } from "react"
import { NewsWrapper, NewsTitle } from "./NewsList.css"
import Button from "../Button"
import { Link } from "gatsby"

class NewsList extends Component {
  render() {
    return (
      <NewsWrapper className="AcLayout__outer-container">
        <NewsTitle>Latest News</NewsTitle>
        <div dangerouslySetInnerHTML={{ __html: this.props.data }} />
        <Button as={Link} to="/news" mt={3} border={1}>
          Read all news
        </Button>
      </NewsWrapper>
    )
  }
}

export default NewsList
