import styled from "styled-components"
import { fontSize, lineHeight, space } from "styled-system"
import theme from "../../../config/theme"

///

export const NewsWrapper = styled.section`
  ${lineHeight}
  ${space}

  border-top: .125rem solid ${theme.colors.fg};
  border-bottom: .125rem solid ${theme.colors.fg};
  text-align: center;

  >*:not(:first-child):not(:last-child) {
    text-align: left;
  }

  ul {
    list-style-type: none;
  }

  li {
    margin-bottom: .25rem;
  }
`
NewsWrapper.defaultProps = {
  mt: 0,
  mb: 4,
  py: 4,
  lineHeight: 2,
}

///

export const NewsTitle = styled.h3`
  ${fontSize}
  ${space}
`
NewsTitle.defaultProps = {
  fontSize: 6,
  mb: [3, 4],
  mt: -2,
}
