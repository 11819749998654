import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import theme from "../../../config/theme"
import { fontSize, space } from "styled-system"
// import { Link } from "gatsby"

///

const primaryStyles = css``
const secondaryStyles = css`
  &:hover {
    background-color: ${theme.colors.secondary};
  }
`
const baseDefaultProps = {
  px: 3,
  py: 2,
}
const BaseButton = styled.button`
  ${fontSize}
  ${space}

  display: inline-block;
  font-family: ${theme.fonts.heading};
  font-weight: 700;
  text-transform: uppercase;

  &:not(:focus),
  &:not(:hover) {
    text-decoration: none;
  }
`

///

export const Button = styled(BaseButton)`
  ${props => props.variant === "primary" && primaryStyles}
  ${props => props.variant === "secondary" && secondaryStyles}

  border: ${props => (props.border ? "2px solid currentColor" : "")};
`

Button.defaultProps = {
  ...baseDefaultProps,
  variant: "secondary",
}

Button.propTypes = {
  variant: PropTypes.oneOf(["primary", "secondary"]),
}
